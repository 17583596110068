import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import style from "../assets/Scss/Pages/DoctorDetails.module.scss";
import { companyDetails, doctorsByDepartment } from "../Confligration/Config"; // Assuming you import the data here
import Model from "../component/Model";
import { Modal, TextField } from "@mui/material";
import optimg from "../assets/images/otpmodel.png";
import right from "../assets/images/right.png";
export default function DoctorDetails() {
  const { id } = useParams(); // Get the doctor ID from the URL
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [name, setname] = useState("");
  const [age, setage] = useState();
  const [error, setError] = useState("");
  const [mobileNumber, setmobilenumber] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [sopen, ssetOpen] = React.useState(false);
  const shandleOpen = () => ssetOpen(true);
  const shandleClose = () => ssetOpen(false);
  const [baopen, basetOpen] = React.useState(false);
  const bahandleOpen = () => basetOpen(true);
  const bahandleClose = () => setOpen(false);
  const [verifyopen, setverifyopen] = React.useState(false);
  const verifyhandleOpen = () => setverifyopen(true);
  const verifyhandleClose = () => setverifyopen(false);
  const [selectedSlots, setSelectedSlots] = useState([null, null]); // for days
  const [showTimeSlots, setShowTimeSlots] = useState(null);

  const days = [
    {
      name: "Monday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Tuesday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Wednesday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Thursday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Friday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Saturday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Sunday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
  ];
  const doctor = Object.values(doctorsByDepartment)
    .flat() // Flatten the nested arrays
    .find((doctor) => doctor.id === parseInt(id)); // Find doctor by ID

  if (!doctor) {
    return <p>Doctor not found</p>;
  }

  return (
    <>
      <div className={style.department_banner}>
        <div className="container"></div>
      </div>
      <div className={style.details}>
        <div className="container">
          <div className={style.wrp}>
            <div className={style.leftSection}>
              <img
                src={doctor.img}
                alt={doctor.label}
                className={style.doctorImage}
              />
            </div>
            <div className={style.rightSection}>
              <h1 className={style.doctorName}>{doctor.label}</h1>
              <h5>{doctor.qualifications}</h5>
              <h5>{doctor.department}</h5>
              <h5 >{doctor.Experience}</h5>
              <p
                className={style.description}
                dangerouslySetInnerHTML={{ __html: doctor.description }}
              ></p>
              {doctor.offerd && <h3>Treatments Offered</h3>}
              <ul>
                {doctor.offerd && doctor.offerd.map(ele =><li>{ele.list}</li> )}
              </ul>
              <button className={style.bookButton} onClick={handleOpen}>
                Book Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose} // Make sure to define this function
      >
        <div
          style={{
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            justifyContent: "center",

            boxShadow: 24,
            p: 4,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            padding: 20,
            borderRadius: 10,
          }}
        >
          <p
            style={{
              color: "#5B5B5B",
              fontSize: 18,
              fontWeight: "700",
              padding: 10,
            }}
          >
            Book Appointment
          </p>
          <p
            style={{
              fontWeight: "400",
              fontSize: 10,
              color: "#3A3A3A",
              padding: 10,
              textAlign: "center",
            }}
          >
           Please call on below number to book your appointment
          </p>
          <p
            style={{
              color: "#5B5B5B",
              fontSize: 18,
              fontWeight: "700",
              padding: 10,
            }}
          >
            {companyDetails.phoneAppoint}
          </p>

          <Link
            style={{
              backgroundColor: "#0097EE",
              paddingLeft: "70px",
              paddingRight: 70,
              color: "white",
              paddingBottom: 5,
              paddingTop: 5,
              borderRadius: 30,
            }}
            onClick={() => {
              bahandleClose();
            }}
          >
            Ok
          </Link>
        </div>
      </Modal>
    </>
  );
}
