import React, { useEffect, useState } from "react";
import style from "../assets/Scss/componnets/Nav.module.scss";
import {
  companyDetails,
  mobilenavMenu,
  navMenu,
  socilMediaIcon,
} from "../Confligration/Config";
import { Link, NavLink } from "react-router-dom";
import Button from "./Button";

import nav_logo from "../assets/images/logo_light.png";

export default function Nav() {
  const [showMenu, setShowMenu] = useState(false);
  const [colorChange, setColorChange] = useState(false);
  const [isNavFixed, setIsNavFixed] = useState(false);

  const basicInfo = [
    {
      icon: "fa-solid fa-headset",
      titel: "24X7 Support ",
      // subTitel:"Emargency COntact",
      value: companyDetails.emargencyNo,
    },
    {
      icon: "fa-solid fa-phone",
      titel: "For Appointment",
      value: companyDetails.phoneAppoint,
    },
  ];

  const openModel = () => {
    // setModel(true)
    console.log("CLick button");
  };
  useEffect(() => {
    const changeNavbarColor = () => {
      if (window.scrollY >= 15) {
        setColorChange(true);
      } else {
        setColorChange(false);
      }
    };

    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  const myFunction = () => {
    setShowMenu(!showMenu);
    document.body.classList.toggle("no-scroll");
  };
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navbarHead = document.querySelector(`.${style.navbar_head}`);

      // Check if navbarHead exists before accessing its properties
      if (navbarHead) {
        const headerHeight = navbarHead.offsetHeight;

        if (scrollPosition > headerHeight) {
          setIsNavFixed(true);
        } else {
          setIsNavFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [showDepartmentMenu, setShowDepartmentMenu] = useState(false); // State to toggle department dropdown

  const toggleDepartmentMenu = () => {
    setShowDepartmentMenu((prev) => !prev); // Toggle the department menu open/close
  };

  return (
    <div>
      <header className={style.header}>
        <div className={style.top_nav}>
          <div className="container">
            <div className={style.top_wrp}>
              <div className={style.logo}>
                <Link to="/">
                  <img src={nav_logo} alt="logo" />
                </Link>
              </div>

              <div className={style.nav_info}>
                <div className={style.contact}>
                  {basicInfo.map((ele) => {
                    return (
                      <div className={style.info}>
                        <i className={ele.icon}></i>
                        <div>
                          <p>{ele.titel}</p>
                          {ele.subTitel && <p>{ele.subTitel}</p>}
                          <h3>{ele.value}</h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.main_menu}>
          <div className="container">
            <div className={style.menu_wrp}>
              <ul>
                {navMenu.map((menu) => (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to={menu.url}
                    >
                      {menu.name}
                    </NavLink>
                  </li>
                ))}
              </ul>

              {/* <Button
                name="Book Appointmnet"
                onPress={openModel}
                icon="fa fa-icon"
              /> */}
            </div>
          </div>
        </div>
      </header>
      <header
        className={`${style.mobile_nav} ${
          colorChange ? style.colorChange : ""
        }`}
      >
        <nav>
          <div className={style.top_nav}>
            <div className="container">
              <div className={style.top_wrp}>
                <div className={style.nav_info}>
                  <div className={style.contact}>
                    {basicInfo.map((ele) => {
                      return (
                        <div className={style.info}>
                          <i className={ele.icon}></i>
                          <div>
                            <p>{ele.titel}</p>
                            <h3>{ele.value}</h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <ul className={style.sosialmidia}>
                  {socilMediaIcon.map((ele) => (
                    <li>
                      <Link to={ele.url}>
                        <i className={ele.icon}></i>
                      </Link>
                    </li>
                  ))}
                </ul> */}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className={style.nav_bar}>
              <div className={style.logo}>
                <Link to="/">
                  <div className={style.logo__}>
                    <img src={nav_logo} alt="logo" />
                  </div>
                </Link>
              </div>

              <div className={style.toggle_button}>
                <button
                  onClick={myFunction}
                  className={showMenu ? style.menuicons : ""}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className={`${style.toggle_menu} ${showMenu ? style.actives : ""}`}>
        <div className={style.menus}>
          <div className="container">
          <ul className={style.navbar_nav}>
            {mobilenavMenu.map((ele, index) => (
              <li key={index} className={showMenu ? style.menuicons : "nav_a"}>
                {ele.submenu ? (
                  <>
                    {/* Department heading */}
                    <span className="nav_heading">{ele.name}</span>

                    {/* Submenu for departments */}
                    <ul className={style.submenu}>
                      {ele.submenu.map((subEle, subIndex) => (
                        <li key={subIndex} className="nav_subitem">
                          {/* Link to department page with department ID */}
                          <Link
                            to={`/department/${subEle.id}`}
                            onClick={(e) => {
                              myFunction(e);
                            }}
                          >
                            {subEle.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  // For other menu items
                  <Link to={ele.url} className="nav_a">
                    {ele.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>

          </div>
        </div>
      </div>
    </div>
  );
}
