import logo from './logo.svg';
import './App.scss';
import Navigation from './Navigation/Navigation';
import WOW from 'wowjs';
import 'animate.css';
import React, { useEffect } from 'react';
function App() {

  useEffect(() => {
    new WOW.WOW().init();
}, []);

  return (
    <Navigation/>
  );
}

export default App;
