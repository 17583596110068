import React from 'react';
import StepCard from './SepCards';
import style from '../assets/Scss/componnets/StepSection.module.scss';
import Heading from './Heading';

export default function StepSection() {
  const steps = [
    {
      id: 1,
      title: 'Make Appointment',
      description: 'Easily book your appointment at Vinayaka Hospital today online',
      image: 'https://img.freepik.com/free-photo/smiling-young-female-doctor-wearing-medical-robe-stethoscope-sitting-desk-with-medical-tools-laptop-holding-clipboard-pointing-with-pen-it-isolated_141793-68813.jpg?t=st=1727640634~exp=1727644234~hmac=b6738b71697e8887aa619f673c666c0c6d2bc256479f72ac9ae83f6a13bf98fc&w=740', // Replace with your image link
    },
    {
      id: 2,
      title: 'Select Expert Doctor',
      description: 'Choose from expert doctors at Vinayaka Hospital for quality care.',
      image: 'https://img.freepik.com/free-photo/medium-shot-doctors-discussing_23-2149355039.jpg?t=st=1727640694~exp=1727644294~hmac=f3bac2708254704ae7ee5cb0be10b7dce98cf69d48e141b7785c062f7e9ebeb0&w=826', // Replace with your image link
    },
    {
      id: 3,
      title: 'Get Consultation',
      description: 'Get expert consultation at Vinayaka Hospital for personalized care',
      image: 'https://img.freepik.com/free-photo/female-doctor-consults-her-patient-holds-documents-her-hands_496169-2913.jpg?t=st=1727640757~exp=1727644357~hmac=b89522d023f8213aa28d9d71469d776ba5c1312ddd3edc40e9be2ad28a209d38&w=826', // Replace with your image link
    },
    {
      id: 4,
      title: 'Get Cure & Relief',
      description: 'Find cure and relief with expert care at Vinayaka Hospital.',
      image: 'https://img.freepik.com/free-photo/little-girl-patient-holding-teddybear-while-listening-healthcare-worker-hospital-bed-treatment_662251-3045.jpg?t=st=1727640843~exp=1727644443~hmac=b648c96869dc63d4cf81bcec5127eb1c8269573ec1bf7b81c64bef18896114f2&w=826', // Replace with your image link
    },
  ];

  return (
    <div className={style.steps_section}>
    <div className="container">
     <Heading titel="How It Helps You Stay Strong"  subTitel="choose Doctor"/>
     
      <div className={style.steps_container}>
        {steps.map(step => (
          <StepCard
            key={step.id}
            id={step.id}
            title={step.title}
            description={step.description}
            image={step.image}
          />
        ))}
      </div>
        </div>
    </div>
  );
}
