import React from "react";
import styles from "../assets/Scss/componnets/Footer.module.scss"; // Import the CSS module
import { companyDetails, departMantSec, socilMediaIcon } from "../Confligration/Config";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo_dark.png"
export default function Footer() {
  const navigate = useNavigate();

  const handleButtonClick = (id) => {
    // Navigate to the department page with the specified ID
    navigate(`/department/${id}`);
  };
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.footerContainer}>
          <div className={styles.footerLeft}>
            <img
              src={logo}
              alt="Vinayaka Hospital Logo"
              className={styles.footerLogo}
            />
            <p>
            Nurturing Health, One Patient at a Time.
            Your Journey to Wellness Starts Here
            </p>
            {/* <ul className={styles.sosialmidia}>
              {socilMediaIcon.map((ele) => (
                <li>
                  <Link to={ele.url}>
                    <i className={ele.icon}></i>
                  </Link>
                </li>
              ))}
            </ul> */}
          </div>

          <div className={styles.footerMiddle}>
            <h3>Our Location</h3>
            <a href="https://www.google.com/maps/place/Karanth+Speciality+Hospital/@12.9160449,77.6193118,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipN6h_4gmPk-9z7Q5KDgrh6tk-aDvipvJKEOtdsL!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipN6h_4gmPk-9z7Q5KDgrh6tk-aDvipvJKEOtdsL%3Dw114-h86-k-no!7i4608!8i3456!4m7!3m6!1s0x3bae14f09fc58e15:0x9bc8322214a2bcb9!8m2!3d12.915927!4d77.6193546!10e5!16s%2Fg%2F1wl4p0zl?hl=en-IN&entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D" target="blank">
            <p>{companyDetails.address1}<br/>{companyDetails.address2}<br/>{companyDetails.address3}<br/>{companyDetails.address4}</p>
            {/* <p>{companyDetails.address1}</p> */}
            </a>          
          </div>
          <div className={styles.footerMiddle}>
            <h3>Center of Excellence</h3>
            {departMantSec.map(ele=><p onClick={() => handleButtonClick(ele.id)}>{ele.name}</p> )}
          </div>
          <div className={styles.footerRight}>
            <h3>Emergency Cases</h3>
            <a href="tel:9845301571" className={styles.emergencyNumber}>
              {companyDetails.emargencyNo}
            </a>
          </div>
        </div>
      </div>

      <div className={styles.footerBottom}>
        <p>©2024 All rights reserved</p>
      </div>
    </footer>
  );
}
