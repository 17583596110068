import React from 'react'
import style from "../assets/Scss/componnets/Heading.module.scss"
export default function Heading({titel,subTitel,description}) {
  return (
    <div>
    <div className={style.heading}>
            <h5>{subTitel}</h5>
            <h1>{titel}</h1>
            <p>{description}</p>
          </div>
    </div>
  )
}
