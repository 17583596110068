// company details

export const companyDetails = {
  companyName: "Vinayaka",
  subTag: "Multispacialist Hospital",
  phoneAppoint: "080 26686583",
  emargencyNo: "+91 98453 01571",
  email: " vinayakahospital72btm@gmail.com",
  address1: "35th Main,100 Feet Ring Road, 72,",
  address2: "1st  Cross Rd, near Central Silk Board, ",
  address3: "KAS Officers Colony, BTM 2nd Stage,",
  address4: "Bengaluru, Karnataka 560068",
};

// socila media icons

export const socilMediaIcon = [
  { url: "", icon: "fa-brands fa-facebook-f" },
  { url: "", icon: "fa-brands fa-instagram" },
  { url: "", icon: "fa-brands fa-linkedin-in" },
];

export const navMenu = [
  { name: "Home", url: "/" },
  // {name:"About", url:"/conatct"},
  {
    name: "Department",
    url: "/department",
  },
];
export const mobilenavMenu = [
  { name: "Home", url: "/" },
  // {name:"About", url:"/conatct"},
 {name:"Department", submenu:[
  { id: "1", name: "Plastic Surgery and Cosmetology" },
  { id: "2", name: "General Physician and Diabetologist" },
  { id: "3", name: "Dermatology, Venereology & Leprosy" },
  { id: "4", name: "Pediatrics" },
  { id: "5", name: "General Surgery " },
  { id: "6", name: "Orthopedics" },
  { id: "7", name: "Urology " },
  { id: "8", name: "Dental " },
  { id: "9", name: "Psychiatric " },
  { id: "10", name: "Physiotherapy " },
  { id: "11", name: "General Medicine " },
  { id: "12", name: "Obstetrics and Gynecology" },
  { id: "13", name: "Family Physician" },

 ]}
     
];
// banner details

export const bannerDetails = [
  {
    image:
      "https://img.freepik.com/free-photo/expressive-young-woman-posing-studio_176474-66963.jpg?t=st=1726682869~exp=1726686469~hmac=e800aa4ce99fa8c2c3a564676139cd8c936f7ec39ffcbe7f1e5c8cf74ae859ae&w=826",
    titel: "Your Wellness is Our Mission",
    info: "Your health is your greatest asset, and at Vinayaka multi-specialty hospital , we are committed to helping you protect and nurture it. From preventive care to specialized treatments, our dedicated team of healthcare professionals is here to support you every step of the way.",
  },
  // {image:"https://img.freepik.com/free-photo/expressive-young-woman-posing-studio_176474-66963.jpg?t=st=1726682869~exp=1726686469~hmac=e800aa4ce99fa8c2c3a564676139cd8c936f7ec39ffcbe7f1e5c8cf74ae859ae&w=826", titel: "Dr. Alice Smith" , info : "Dr. Alice Smith is a leading cardiologist with over 20 years of experience in treating heart conditions. She has published numerous research papers and is a member of several professional cardiology societies."},
  // {image:"https://img.freepik.com/free-photo/expressive-young-woman-posing-studio_176474-66963.jpg?t=st=1726682869~exp=1726686469~hmac=e800aa4ce99fa8c2c3a564676139cd8c936f7ec39ffcbe7f1e5c8cf74ae859ae&w=826", titel: "Dr. Alice Smith" , info : "Dr. Alice Smith is a leading cardiologist with over 20 years of experience in treating heart conditions. She has published numerous research papers and is a member of several professional cardiology societies."},
];

export const departMantSec = [
  {
    id: "1",
    name: "Plastic Surgery and Cosmetology",
    img: "https://img.freepik.com/free-photo/close-up-doctor-with-mask-checking-patient_23-2149103634.jpg?t=st=1727870770~exp=1727874370~hmac=c2090a162370389eb7cff48bc2376314a5c53acfad60397387c88a4529bf16df&w=826",
    url: "",
    description2:
      "At Vinayaka Multispeciality Hospital, our Department of Plastic Surgery and Cosmetology is committed to delivering exceptional care tailored to the unique needs of each patient. Our experienced team of board-certified plastic surgeons and licensed cosmetologists utilizes state-of-the-art techniques and technologies to provide a comprehensive range of services, including reconstructive surgery, aesthetic enhancements, and non-invasive cosmetic treatments. We prioritize your safety and satisfaction, ensuring that every procedure is approached with the utmost care and professionalism.",
    description:
      "We understand that the journey to achieving your aesthetic goals is both personal and transformative. That’s why we emphasize a holistic approach, considering not only the physical aspects but also the emotional well-being of our patients. From your initial consultation to post-treatment support, our dedicated staff is here to guide you through every step, ensuring you feel confident and informed about your choices. Experience the difference at Vinayaka Multispeciality Hospital, where your beauty and health are our top priorities.",
  },
  {
    id: "2",
    name: "General Physician and Diabetologist",
    img: "https://img.freepik.com/free-photo/doctor-giving-medical-equipment-patient_23-2148285691.jpg?t=st=1727294806~exp=1727298406~hmac=185c71eaa404be4ed4ffa76d73f9fc05fb15bbf040e8671f3429292222d6628f&w=826",
    url: "",
    description2:
      "Our Diabetologists specialize in the diagnosis, treatment, and management of diabetes, providing expert care for both Type 1 and Type 2 diabetes as well as gestational diabetes. Utilizing the latest advancements in diabetes care, our Diabetologists work closely with you to develop individualized treatment plans that include blood sugar monitoring, medication management, nutritional counseling, and lifestyle coaching. Our goal is to empower you with the knowledge and tools to effectively manage your diabetes and enhance your quality of life. At Vinayaka multi-specialty hospital, we are committed to offering compassionate, high-quality care to help you achieve and maintain optimal health.",
    description:
      "Welcome to the General Physician and Diabetology services at Vinayaka multi-specialty hospital, where your health is our priority. Our General Physicians are dedicated to offering comprehensive primary care for a wide range of health issues, from routine check-ups to acute illnesses. With a focus on preventive care and early diagnosis, our experienced team is committed to helping you maintain optimal health and well-being. Whether you need annual physicals, immunizations, or management of chronic conditions, our General Physicians provide personalized care tailored to your unique needs.",
  },
  {
    id: "3",
    name: "Dermatology, Venereology & Leprosy",
    img: "https://img.freepik.com/free-photo/girl-receiving-facial-treatment-beauty-salon_23-2148194180.jpg?t=st=1727294898~exp=1727298498~hmac=ef0054ca0aac252e66b9c9831b4709f90de751a9bdc3fdb0314809497d03a43d&w=826",
    url: "",
    description2:
      "At Vinayaka multi-specialty hospital, we utilize the latest advancements in dermatological science and cosmetic technology to deliver safe and effective treatments. From medical dermatology services like acne treatment and skin cancer screenings to cosmetic procedures such as chemical peels, laser therapy, and Botox, we are committed to helping you achieve your aesthetic goals with the highest standard of care. Our holistic approach ensures that you not only look your best but also feel confident and rejuvenated. Experience the transformative care at our Cosmetology and Dermatology Department—where science meets beauty for exceptional results.",
    description:
      "Welcome to the Cosmetology and Dermatology Department at Vinayaka multi-specialty hospital, where we combine medical expertise with aesthetic excellence to address all your skin, hair, and nail concerns. Our team of board-certified dermatologists and experienced cosmetologists is dedicated to providing personalized care that enhances your natural beauty while ensuring the health and well-being of your skin. Whether you're dealing with a chronic skin condition, seeking anti-aging treatments, or looking to improve your overall appearance, we offer a comprehensive range of services tailored to your specific needs.",
  },
  {
    id: "4",
    name: "Pediatrics",
    img: "https://img.freepik.com/free-photo/doctor-doing-their-work-pediatrics-office_23-2149224144.jpg?t=st=1727294941~exp=1727298541~hmac=4a01b882cf1b4e125e6badacea0da8972379a95d21f3780caae5d11be65a9846&w=826",
    url: "",
    description2:
      "At Vinayaka multi-specialty hospital, we believe in a holistic approach to pediatric care, addressing not only physical health but also emotional and developmental needs. Our state-of-the-art facilities are equipped with the latest medical technology to ensure accurate diagnoses and effective treatments. Whether it's routine check-ups, vaccinations, or specialized care for chronic conditions, we strive to create a nurturing and friendly environment where children feel safe and parents feel supported. Trust us to be your partner in fostering a healthy future for your child.",
    description:
      "Welcome to the Pediatrics Department at Vinayaka multi-specialty hospital, where the health and happiness of your child are our top priorities. Our team of experienced pediatricians and healthcare professionals is dedicated to providing comprehensive medical care for children from infancy through adolescence. We understand that each stage of a child's development brings unique health needs, and we are committed to offering personalized care that supports their growth and well-being.",
  },
  {
    id: "5",
    name: "General Surgery ",
    img: "https://img.freepik.com/free-photo/surgeons-operating-lighting-equipment-male-female-doctors-are-wearing-blue-scrubs-they-are-working-hospital_657921-1085.jpg?t=st=1727294993~exp=1727298593~hmac=a1373e4061cec5c86bdc6a11265e65632869cf5d3939be25f4a6528c067eacac&w=826",
    url: "",
    description2:
      "At  Vinayaka multi-specialty hospital, we understand that undergoing surgery can be a daunting experience. That's why we prioritize clear communication, compassionate care, and a supportive environment throughout your surgical journey. From initial consultation to post-operative recovery, our multidisciplinary team works closely with you to develop a personalized treatment plan tailored to your specific needs. Trust the MD General Surgery Department at  Vinayaka multi-specialty hospital to provide exceptional surgical care with a focus on your health, safety, and well-being.",
    description:
      "Welcome to the  General Surgery Department at Vinayaka multi-specialty hospital, where we specialize in providing expert surgical care for a wide range of conditions. Our team of board-certified general surgeons is dedicated to delivering high-quality, patient-centered care, utilizing the latest surgical techniques and state-of-the-art technology. Whether you require a routine procedure or a complex surgery, our skilled surgeons are committed to ensuring the best possible outcomes for you.",
  },
  {
    id: "6",
    name: "Orthopedics",
    img: "https://img.freepik.com/free-photo/orthopedic-specialist-explaining-bones-injury-human-skeleton-woman-medical-cabinet-physician-pointing-spinal-cord-osteopathy-model-show-anatomy-structure-diagnosis_482257-38551.jpg?t=st=1727295038~exp=1727298638~hmac=619d04e55de3287a446689bcc4842d6945977a3dda5bb51bc307db8ad1a521e7&w=826",
    url: "",
    description2:
      "Our state-of-the-art facilities are equipped with the latest technology and advanced surgical techniques to ensure the best possible outcomes for our patients. From minimally invasive procedures to complex reconstructive surgeries, we employ evidence-based practices to deliver effective and efficient care. At Vinayaka multi-specialty hospital, we prioritize your recovery and well-being, offering holistic support that includes pain management, physical therapy, and ongoing monitoring. Trust us to help you get back to doing the things you love, pain-free and stronger than ever.",
    description:
      "Welcome to the Orthopedics Department at Vinayaka multi-specialty hospital, where we specialize in the diagnosis, treatment, and rehabilitation of musculoskeletal conditions. Our dedicated team of orthopedic surgeons, physical therapists, and healthcare professionals is committed to providing comprehensive care for issues related to bones, joints, muscles, ligaments, and tendons. Whether you're dealing with a sports injury, arthritis, or a complex orthopedic condition, we offer personalized treatment plans designed to restore your mobility and improve your quality of life.",
  },
  {
    id: "7",
    name: "Urology ",
    img: "https://img.freepik.com/free-photo/front-view-doctor-holding-anatomic-model_23-2149351652.jpg?t=st=1727295147~exp=1727298747~hmac=b0e7f583d54ef0a5e6bf6ae716ae55c464098aada9304fc767b535f3f3f16ae0&w=826",
    url: "",
    description2:
      "At Vinayaka multi-specialty hospital, we understand the sensitive nature of urological concerns and strive to create a compassionate and supportive environment for all our patients. From routine check-ups and screenings to advanced surgical procedures, our goal is to ensure your health, comfort, and well-being. Trust our skilled team to guide you through every step of your treatment journey, delivering high-quality care that you can rely on.",
    description:
      "Welcome to the Urology Department at Vinayaka multi-specialty hospital , where we specialize in the diagnosis, treatment, and management of urinary tract and male reproductive system conditions. Our team of experienced urologists and healthcare professionals is committed to providing comprehensive, patient-centered care using the latest medical advancements and technologies. Whether you're dealing with a common urological issue or a complex condition, we are here to offer expert solutions tailored to your specific needs.",
  },
  {
    id: "8",
    name: "Dental ",
    img: "https://img.freepik.com/free-photo/dentist-doing-check-up-patient_23-2149206225.jpg?t=st=1727639375~exp=1727642975~hmac=edd3e6631e7b5b4d2c593018a7e790cd1984a008d842586e151b4b98bc818978&w=826",
    url: "",
    description2:
      "At Vinayaka Multi-Speciality Hospital, our Dental Department is dedicated to providing comprehensive oral care tailored to your unique needs. Our team of experienced dentists utilizes the latest technology and techniques to ensure that you receive the highest quality treatment in a comfortable environment. Whether you require routine check-ups, preventive care, cosmetic dentistry, or advanced restorative procedures, we are here to help you achieve a healthy, radiant smile.",
    description:
      "We understand that visiting the dentist can be a source of anxiety for many patients, which is why we prioritize a patient-centered approach. Our friendly staff is committed to making your experience as pleasant as possible, ensuring that you feel informed and at ease throughout your treatment. At Vinayaka, your dental health is our priority, and we strive to create a lasting relationship built on trust and excellence. Let us help you smile with confidence!",
  },
  {
    id: "9",
    name: "Psychiatric ",
    img: "https://img.freepik.com/free-photo/accompaniment-abortion-process_23-2149656079.jpg?t=st=1727641607~exp=1727645207~hmac=b2c6cebca63d87c3edc24af3271048c29e4653ea060c46baee9c7b9c91079b37&w=826",
    url: "",
    description2:
      "At Vinayaka Multi-Speciality Hospital, our Psychiatric Department is dedicated to providing compassionate, comprehensive mental health care tailored to the needs of each individual. Our team of experienced psychiatrists, psychologists, and therapists work collaboratively to assess, diagnose, and treat a wide range of mental health conditions, including anxiety, depression, mood disorders, and more. We utilize evidence-based therapies and innovative treatment options to support your journey toward mental wellness.",
    description:
      "We understand that seeking help can be a daunting experience, which is why we emphasize a supportive and respectful environment. Our approach is centered on empathy and understanding, ensuring that you feel safe and heard throughout your treatment. We offer personalized care plans that may include therapy, medication management, and lifestyle guidance. At Vinayaka, your mental health is our priority, and we are here to empower you on the path to recovery and well-being. You are not alone—let us support you in achieving a brighter tomorrow.",
  },
  {
    id: "10",
    name: "Physiotherapy ",
    img: "https://img.freepik.com/free-photo/woman-doing-leg-exercises-hospital_482257-83737.jpg?t=st=1727641548~exp=1727645148~hmac=a68a779bd8357bfaf149822a211a543b17859a6fdfa96ba0e5b27b06db9b2709&w=826",
    url: "",
    description2:
      "The Physiotherapy Department at Vinayaka Multi-Speciality Hospital is committed to helping you regain mobility, relieve pain, and enhance your overall quality of life. Our team of skilled physiotherapists utilizes a holistic approach to assess and treat a wide range of conditions, including musculoskeletal injuries, post-operative rehabilitation, neurological disorders, and chronic pain syndromes. We focus on evidence-based techniques and personalized treatment plans designed to meet your specific needs and goals.",
    description:
      "We believe that every patient deserves compassionate care and support on their recovery journey. Our state-of-the-art facilities are equipped with the latest technology and rehabilitation tools, enabling us to provide effective treatments in a comfortable environment. From manual therapy and therapeutic exercises to education on injury prevention, we empower you with the knowledge and skills needed to maintain your health long-term. At Vinayaka, we are dedicated to helping you move better, feel better, and live better. Your recovery starts here!",
  },
  {
    id: "11",
    name: "General Medicine ",
    img: "https://img.freepik.com/free-photo/young-male-psysician-with-patient-measuring-blood-pressure_1303-17877.jpg?t=st=1727641370~exp=1727644970~hmac=c281a4b5d61c2e4a127c46467d18422847ecd9e2bc111e49a8e1f9cb290ddd99&w=826",
    url: "",
    description:
      "At Vinayaka Hospital, the General Medicine department is dedicated to providing comprehensive care for a wide range of health conditions. Our team of highly skilled physicians focuses on the diagnosis, treatment, and management of acute and chronic illnesses. From routine check-ups to complex medical conditions, we ensure that our patients receive the best possible care tailored to their individual needs. We emphasize preventive medicine, guiding patients on maintaining a healthy lifestyle to reduce the risk of disease.",
    description2:
      "Our department is equipped with state-of-the-art medical facilities, ensuring accurate diagnosis and effective treatments. Whether it’s managing long-term conditions like hypertension and diabetes or addressing sudden health concerns, we take a patient-centered approach. With a strong commitment to clinical excellence and compassionate care, Vinayaka Hospital's General Medicine team is here to support your journey to better health.",
  },
  {
    id: "12",
    name: "Obstetrics and Gynecology",
    img: "https://img.freepik.com/free-photo/gynecologist-performing-ultrasound-consultation_23-2149353025.jpg?t=st=1727641429~exp=1727645029~hmac=c3e1e4ed0cbd38f82d78eba636d144a06bed8f03bd86a200a6ba3b8f44f127d9&w=826",
    url: "",
    description:
      "Welcome to the Department of Obstetrics and Gynecology, where we are dedicated to providing comprehensive care for women at every stage of life. Our team of experienced specialists focuses on both obstetric and gynecologic health, ensuring that our patients receive personalized care tailored to their unique needs. From routine check-ups and preventive screenings to complex pregnancies and specialized treatments, we prioritize the health and well-being of our patients and their families. Our state-of-the-art facilities and compassionate approach empower women to take charge of their reproductive health.",
    description2:
      "In addition to exceptional clinical services, our department is committed to education and support. We offer resources on a variety of topics, including family planning, menstrual health, and menopause management, helping women make informed decisions about their health. Our specialists are dedicated to staying at the forefront of medical advancements, utilizing the latest research and technology to enhance patient outcomes. At the Department of Obstetrics and Gynecology, we strive to create a supportive environment where every woman feels valued and cared for throughout her reproductive journey.",
  },
  {
    id: "13",
    name: "Family Physician",
    img: "https://img.freepik.com/free-photo/physician-doctor-discussing-sickness-symptoms-with-retired-elderly-senior_482257-12319.jpg?t=st=1727294412~exp=1727298012~hmac=48c90fd47fae65b389ac27bbe9f46df83bcc7996e9f4ccae64845415fd3f5616&w=826",
    url: "",
    description:
      "Welcome to the Family Physician Department at Vinayaka multi-specialty hospital,, where we prioritize your family's health and well-being. Our family physicians are dedicated to providing comprehensive, continuous, and personalized healthcare for patients of all ages. From newborns to seniors, we offer a wide range of medical services tailored to meet the unique needs of each family member. Our holistic approach ensures that we not only address immediate health concerns but also focus on preventive care, chronic disease management, and overall wellness.",
    description2:
      "At Vinayaka multi-specialty hospital, we believe in building lasting relationships with our patients. Our family physicians take the time to get to know you and your family, understanding your medical history, lifestyle, and health goals. This personalized care allows us to create effective treatment plans and offer sound medical advice that fits your specific needs. Whether it's routine check-ups, vaccinations, or managing complex health conditions, you can trust our experienced family physicians to provide compassionate and expert care for your entire family.",
  },
];

export const doctorsByDepartment = {

  1:[
    {
      id: 1,
      label: "Dr. Harini B S",
      img: "/doctor/14.png",
      qualifications: "MBBS, MS, MCH, DrNB",
      department: "Plastic surgeon and Cosmetology",
      Experience: "10 Years Experience",
      description:`Dr Harini is a board certified Plastic surgeon with specialisation in cosmetic surgery. She has been extensively trained in some of the best institutes nationally and internationally. She completed her M.B.B.S from the prestigious Mysore Medical college & Research institute and post-graduation M.S in general surgery from Karnataka institute of medical sciences Hubli. During residency she found her calling for plastic and reconstructive surgery.<br/>
      She completed her super-speciality M.Ch in burns, plastic and reconstructive surgery from Atal Bihari Vajpayee Institute of Medical Sciences and Dr. Ram Manohar Lohia Hospital, New Delhi as top of her batch in University Examination. She also has Doctorate in National Board – DrNB in Plastic Surgery.<br/>
      After completing super-speciality she trained again in cosmetic plastic surgery in Coimbatore under Roy Kanjoor & Dr Vikram Kumar, in Mumbai under Dr Milan Doshi and She was awarded, the prestigious ISAPS fellowship for training in Aesthetic Plastic surgery, from Cali, Colombia (South America) Under Dr Lina Triana – ISAPS president 2022-2024. She has also done Microsurgery Course from Ganga Hospital Coimbatore.<br/>
      Dr Harini believes in perfection in her work and committed to provide best care for all her patients.<br/>
      Her training includes extensive work in plastic surgery. Over the years she has performed numerous successful liposuction surgeries for Gynaecomastia, breast augmentation & reduction, breast lift, body contouring, mommy makeovers, tummy tuck surgery, rhinoplasties, blepharoplasty, dimple creation, Hair transplant, congenital anomaly and vascular malformations management. She is also a expert in dealing with facial trauma cases which includes facial fractures.<br/>
      She has special inclination towards cosmetic procedures and specialises in non surgical facial rejuvenation, fillers, Botox, facial peels ,PRP, LASERS and mesotherapy. She is Member of various societies like Indian association of Aesthetic Plastic Surgeons ,International Society Of Aesthetic Plastic Surgeons (ISAPS) ,Association of Surgeons of India, National Academy of Burns India.<br/>`
    },
  ],
  
  2: [
    {
      id: 2,
      label: "Dr. Sudhindra D",
      img: "/doctor/plase_holder2.png",
      qualifications: "MBBS, MD ,CIDC(UK)",
      department: "General Physician and Diabetologist",
      Experience: "28 Years Experience Overall  (23 years as specialist)",
      description:
        "Dr. Sudhindra works as a Diabetologist at Rajguru Clinic JP Nagar, Bangalore. He specializes in the field of Diabetology, Endocrinology and General Medicine. He is a qualified MBBS from Kohler Medical College. Dr. Sudhindra has done Masters in General Medicine from Devaraj URS Medical College Kolar. He has received Certificate in Diabetes Care (CIDC) from University of Warwick United Kingdoms. Dr. Sudhindra has worked as a consultant physician at Manipal Clinic and Sagar Clinic Bangalore. He was awarded National Merit Scholarship in 1995. Dr. Sudhindra is a member of Indian Medical Association (IMA) and Association of Physicians of India. He is also registered with Karnataka Medical Council.",
    },
  ],
  3: [
    {
      id: 3,
      label: "Dr. Chandrashekar Aithal",
      img: "/doctor/plase_holder2.png",
      qualifications: "MBBS, DVD",
      department: "Cosmetologist, Dermatologist, venereologist",
      Experience: "34 Years Experience Overall  (28 years as specialist)",
      description: `Dr. Chandrashekara Aithal is a Dermatologist and Cosmetologist in BTM Layout 2nd Stage, Bangalore and has an experience of 22 years in these fields. He completed MBBS from Vijayanagara Institute of Medical Sciences (VIMS), Bellary in 1989 and DVD from Government Medical College, Mysore in 1996.<br/>
            He is a member of Indian Association of Dermatologists, Venereologists and Leprologists (IADVL), Karnataka Medical Council, Association of Cutaneous Surgeons of India (ACSI) and American Academy of Dermatology (AAD). Some of the services provided by the doctor are: Skin Biopsy, Botox Injections, Melasma Treatment, Hyper Pigmentation Treatment and Dental Fillings etc`,
    },
  ],
  4: [
    {
      id: 4,
      label: "Dr. Deva Kumar",
      img: "/doctor/4.png",
      qualifications: "MBBS, BSc, Diploma in child health",
      department: "DCH , DNB – Paediatrics",
      Experience: "27 Years Experience Overall  (22 years as specialist)",
      description: `Dr. Deva Kumar is a leading Pediatrician Currently associated with Green View Medical Center(Bangalore). His specialization lies in General Pediatric with the special interest in Neurology and Bronchial Asthma at Baptist Hospital, Bangalore Hospital and Indira Gandhi Institute of Child Health, Bangalore.Apart from being a member of the Indian Pediatric Association, Karnataka Medical Council, Dr. Deva is committed to providing the best healthcare treatment for children.<br/>
       Dr. Deva' s approach lies in performing diagnostic tests to obtain information about patients medical condition and administers therapies, medications, and vaccinations to treat illness, disorders, and injuries. A part of being a pediatrician is not only dealing with the children but also dealing with the family. With Structured evaluation and early intervention, Dr. Deva believes in identifying and addressing developmental and behavioral problems that result from exposure to stress`,
    },

    {
      id: 5,
      label: "Dr. Siddaraju ML",
      img: "/doctor/plase_holder2.png",
      qualifications: "MBBS, MD - Pediatrics",
      department: "Paediatrics",
      Experience: "45 Years Experience Overall  (42 years as specialist)",
      description: `Dr. Siddaraju M.L. is a highly respected pediatrician with an impressive 45 
      years of experience in caring for children’s health. He completed his MBBS 
      from Bangalore University in 1974, followed by an MD in Pediatrics from the 
      same institution in 1979. Over the decades, Dr. Siddaraju has built a 
      reputation for his compassionate approach and dedication to the well-being 
      of his young patients. His extensive knowledge encompasses a wide range of 
      pediatric conditions, ensuring that each child receives comprehensive and 
      individualized care.<br/>
      Throughout his distinguished career, Dr. Siddaraju has remained committed to 
      staying at the forefront of pediatric medicine. His experience allows him to 
      effectively diagnose and treat various childhood illnesses, as well as provide 
      valuable guidance to parents on developmental milestones and preventive 
      health. With a strong emphasis on creating a supportive and nurturing 
      environment, Dr. Siddaraju continues to make a significant impact on the lives 
      of countless children and their families.`,
    },
  ],
  5: [
    {
      id: 6,
      label: "Dr. Mahanth H.M",
      img: "/doctor/5.png",
      qualifications: "MBBS, MD",
      department: "MD General Surgeon",
      Experience: "7 Years Experience",
      description: `Dr. Mahanth H.M is a skilled and dedicated general surgeon specializing in a 
                    wide range of surgical procedures. With a commitment to patient care and a 
                    focus on minimally invasive techniques, Dr. Mahanth offers expertise in 
                    various general surgeries, including appendectomy, breast surgery, 
                    exploratory laparotomy, hernia repair, gallbladder surgery (cholecystectomy), 
                    and varicose vein surgeries. Additionally, he is proficient in the surgical 
                    management of fissures, piles (hemorrhoids), and fistula surgeries, ensuring 
                    comprehensive care for conditions affecting the gastrointestinal tract. His 
                    thorough approach ensures that each patient receives personalized treatment 
                    tailored to their unique medical needs.<br/>
                    Dr. Mahanth has earned prestigious accolades in his field, including 
                    Fellowships in Minimal Access Surgery (FMAS) and the Indian Association of 
                    Gastrointestinal Endo Surgeons (FIAGES). With years of experience and a 
                    passion for advancing surgical techniques, he remains dedicated to providing 
                    the highest standard of care. Dr. Mahanth's focus on both surgical precision 
                    and compassionate patient support makes him a trusted choice for individuals 
                    seeking expert general surgical care.`,
    },
  ],
  6: [
    {
      id: 7,
      label: "Dr. Ragavendra R",
      img: "/doctor/6.png",
      qualifications: "MBBS, MS(Orthopedics)",
      department: "Orthopedic Surgeon",
      Experience: "13 Years Experience",
      description: ` Dr. Ragavendra R is a highly accomplished orthopedic surgeon specializing in 
the treatment of all trauma cases, including both basic and advanced trauma 
care. With extensive expertise in knee and hip replacement, spine surgery, 
arthroscopic surgery, and soft tissue reconstruction surgeries, Dr. Ragavendra 
is dedicated to restoring mobility and improving the quality of life for his 
patients. His comprehensive approach combines innovative techniques with a 
commitment to patient-centered care, ensuring that each individual receives 
tailored treatment based on their specific needs.<br/>
With over 16 years of experience and more than 3,000 successful surgeries to 
his credit, Dr. Ragavendra has earned a reputation for excellence in the field. 
He has completed a prestigious fellowship in joint replacement, further 
enhancing his skills and knowledge in orthopedic surgery. Dr. Ragavendra is 
passionate about advancing orthopedic care through research and education, 
and he is dedicated to staying at the forefront of medical advancements. His 
compassionate approach and commitment to patient outcomes make him a 
trusted choice for individuals seeking comprehensive orthopedic care`,
    },
  ],
  7: [
    {
      id: 8,
      label: "Dr. Narasimhaiah K",
      img: "/doctor/plase_holder2.png",
      qualifications: " MBBS , MS (Uro)",
      department: "Urologist",
      Experience: "15 Years Experience",
      offerd: "",
      // description: "Dr. Deva Kumar is a leading Pediatrician Currently associated with Green View Medical Center(Bangalore). His specialization lies in General Pediatric with the special interest in Neurology and Bronchial Asthma at Baptist Hospital, Bangalore Hospital and Indira Gandhi Institute of Child Health, Bangalore.Apart from being a member of the Indian Pediatric Association, Karnataka Medical Council, Dr. Deva is committed to providing the best healthcare treatment for children. Dr. Deva' s approach lies in performing diagnostic tests to obtain information about patients medical condition and administers therapies, medications, and vaccinations to treat illness, disorders, and injuries. A part of being a pediatrician is not only dealing with the children but also dealing with the family. With Structured evaluation and early intervention, Dr. Deva believes in identifying and addressing developmental and behavioral problems that result from exposure to stress"
    },
    {
      id: 9,
      label: "Narasimhaiah k ",
      img: "/doctor/15.png",
      qualifications: "MBBS . MS Generalsurgery . M ch urology. ",
      department: "Urologist",
      Experience: "27 years  experience and 16 years as urologist",
      offerd: "",
      // description: "Dr. Deva Kumar is a leading Pediatrician Currently associated with Green View Medical Center(Bangalore). His specialization lies in General Pediatric with the special interest in Neurology and Bronchial Asthma at Baptist Hospital, Bangalore Hospital and Indira Gandhi Institute of Child Health, Bangalore.Apart from being a member of the Indian Pediatric Association, Karnataka Medical Council, Dr. Deva is committed to providing the best healthcare treatment for children. Dr. Deva' s approach lies in performing diagnostic tests to obtain information about patients medical condition and administers therapies, medications, and vaccinations to treat illness, disorders, and injuries. A part of being a pediatrician is not only dealing with the children but also dealing with the family. With Structured evaluation and early intervention, Dr. Deva believes in identifying and addressing developmental and behavioral problems that result from exposure to stress"
    },
  ],
  8: [
    {
      id: 10,
      label: "Dr. Bharati Abhay Patil",
      img: "/doctor/8.png",
      qualifications: "MDS - Oral Medicine & Radiology",
      department: "Dentist",
      Experience: "24 Years Experience",
      description: `Dr. Bharati Abhay Patil is a highly skilled and dedicated dentist specializing in general dentistry, with a particular focus on restorative and cosmetic dentistry, implants, and orofacial pain management. With years of experience in the field, Dr. Patil combines her extensive knowledge with a compassionate approach to patient care, ensuring that each individual receives personalized treatment tailored to their unique needs. Her expertise in the latest dental techniques and technologies allows her to provide high-quality care, helping patients achieve and maintain optimal oral health.<br/> 
        In addition to her clinical practice, Dr. Patil is the esteemed Professor and Head of the Department of Oral Medicine & Radiology at The Oxford Dental College in Bengaluru. Her commitment to education and mentorship has earned her numerous accolades in the dental community. Dr. Patil is dedicated to advancing the field of dentistry through research and innovative practices, ensuring her patients benefit from the most current and effective treatments available. With a focus on both functional and aesthetic outcomes, she strives to create beautiful smiles while enhancing her patients' overall well-being`,
      offerd: "",
    },
  ],
  9: [
    {
      id: 11,
      label: "Dr. Mamatha Shetty",
      img: "/doctor/plase_holder3.png",
      qualifications: "MBBS, DPM (Psychiatry)",
      department: "Psychiatry",
      Experience: "44 Years Experience Overall  (35 years as specialist)",
      description: `Dr. Mamatha Shetty is a Psychiatrist and Addiction Psychiatrist in Wilson Garden, Bangalore and has an experience of 44 years in these fields. Dr. Mamatha Shetty practices at Parijma Neurodiagnostic & Rehabilitation Centre in Wilson Garden, Bangalore,Sri Sai Ram Hospital in BTM Layout 2nd Stage, Bangalore and Greenview Medical Center in HSR Layout, Bangalore. She completed MBBS from Bangalore Medical College and Research Institute, Bangalore in 1980 and DPM (Psychiatry) from National Institute of Mental Health and Neurosciences (NIMHANS) in 1985.<br/> She is a member of IPS and IAPP. Some of the services provided by the doctor are: Grief Counselling,Marriage/ Marital Counselling,Memory Improvement,Drug Abuse & DeAddiction Therapy and Anger Management etc.`,
      offerd: "",
    },
  ],
  10: [
    {
      id: 12,
      label: "Dr. M.Geetha Rani",
      img: "/doctor/10.png",
      qualifications:
        "B.P.T.,SVIMS,Tirupat,M.P.T.,(Neurological & Psychosomatic Disorders),SVIMS(Tirupati)",
      department: "Physiotherapist",
      Experience: "18 Years Experience",
      description: `M. Geetha Rani is a dedicated physiotherapist specializing in a comprehensive range of rehabilitation services aimed at enhancing patient recovery and quality of life. With expertise in stroke rehabilitation and post-surgical orthopedic rehabilitation, she provides tailored treatment plans to support individuals in regaining strength and mobility after injury or surgery.Her approach includes a combination of targeted exercises and advancedelectrotherapy techniques, addressing various conditions such as Bell's Palsy,sciatica, foot drop, Parkinson’s disease, nerve injuries, and osteoarthritis.
      <br/>In addition to her focus on stroke and orthopedic rehabilitation, Geetha Rani is skilled in managing complex conditions like cervical and lumbar spondylosis, frozen shoulder, and post-traumatic stiffness affecting the shoulder, elbow, wrist, knee, and ankle. She also offers specialized care for ligament injuries, multiple sclerosis, cerebral palsy, and neuropathy, utilizing techniques such as spinal manipulation to alleviate pain and improve function. With a commitment to holistic patient care, M. Geetha Rani empowers individuals on their rehabilitation journey, helping them achieve their personal health and wellness goals.`,
      offerd: "",
    },
  ],
  11: [
    {
      id: 13,
      label: "Dr. Pushpa",
      img: "/doctor/11.png",
      qualifications: "MBBS",
      department: "General Medicine",
      Experience: "30 Years Experience",
      // description: "Stroke Rehabilitation, Rehabilitation for post surgical Orthopaedic patients, Exercise and Electrotherapy  for conditions like Bell's Palsy,Sciatica,Foot drop,Parkinson's disease,Nerve injuries,Osteoarthritis,Cervical Spondylosis,Lumbar Spondylosis,Frozen Shoulder,Post traumatic stiffness of Shoulder,Elbow,Wrist,Knee and Ankle,Ligament injuries,Parkinson's disease, Multiple Sclerosis,Cerebral Palsy,Spinal manipulation,Neuropathy"
      offerd: "",
    },
  ],
  12: [
    {
      id: 14,
      label: "Dr. Suma Karanth",
      img: "/doctor/plase_holder3.png",
      qualifications: "MBBS, MD ",
      Experience: "35 Years Experience",
      department: "Gynecologist and Obstetrician",
      description: `Dr. Suma Karanth, an experienced gynecologist, is dedicated to addressing the unique needs of women and their families in a calm and welcoming environment. Fluent in Kannada, Hindi, and English, she ensures every patient feels comfortable and understood. From routine check-ups to specialized treatments like hysteroscopy and IUI. Dr Suma Karanth provides comprehensive care. schedule your consultation today at Vinayaka Multi Speciality Hospital`,
      offerd: [
        { list: "Delivery Procedure" },
        { list: "Women's Health" },
        { list: "Caesarean Section Procedure" },
        { list: "Treatment Of Female Sexual Problems" },
        { list: "Treatment Of Pregnancy Problems" },
        { list: "Hysterectomy Procedure" },
        { list: "Hysteroscopy Procedure" },
        { list: "Woman Health check" },
        { list: "Treatment Of Medical Diseases InPregnancy" },
        { list: "Hymenoplasty Procedure" },
        { list: "Treatment Of Menstrual Problems" },
        { list: "Intra-Uterine Insemination (IUI) Treatment" },
        { list: "Gynecology Laparoscopy Procedures Pcod Treatment" },
        { list: "Pregnancy-Related Disorders" },
        { list: "Vaginal Cancer" },
        { list: "Excessive Menstrual Bleeding" },
        { list: "Venous Thrombosis and Phlebitis in Pregnancy" },
        { list: "Hemorrhage from Placenta Previa" },
        { list: "Endometriosis" },
        { list: "Multiple Gestation" },
        { list: "Polycystic Ovarian Syndrome" },
        { list: "Genitourinary Tract Infections in Pregnancy" },
      ],
    },
   
  ],
  13: [
    {
      id: 15,
      label: "Dr. Joshi Venkatesh Karanth",
      img: "/doctor/1.png",
      qualifications: "MBBS",
      department: "Family Physician ",
      Experience: "35 Years Experience ",
      // description: "Dr. Sudhindra works as a Diabetologist at Rajguru Clinic JP Nagar, Bangalore. He specializes in the field of Diabetology, Endocrinology and General Medicine. He is a qualified MBBS from Kohler Medical College. Dr. Sudhindra has done Masters in General Medicine from Devaraj URS Medical College Kolar. He has received Certificate in Diabetes Care (CIDC) from University of Warwick United Kingdoms. Dr. Sudhindra has worked as a consultant physician at Manipal Clinic and Sagar Clinic Bangalore. He was awarded National Merit Scholarship in 1995. Dr. Sudhindra is a member of Indian Medical Association (IMA) and Association of Physicians of India. He is also registered with Karnataka Medical Council."
    },
  ],
};

export const aboutInfo = {
  heading: "A great place for medical care",
  titel: "Introduction",
  subTitel:
    "20 years of experience, certified doctors & using modern technology",
  certiFy: [
    {
      img: "https://img.freepik.com/free-vector/certificate-template-with-elegant-elements_23-2148568461.jpg?t=st=1726815200~exp=1726818800~hmac=6fc5d66300495b62e14284ad81cfb1e193f3a0c5dd1a6dca7804847f317e3ae4&w=826",
    },
    {
      img: "https://img.freepik.com/free-vector/certificate-template-with-elegant-elements_23-2148568461.jpg?t=st=1726815200~exp=1726818800~hmac=6fc5d66300495b62e14284ad81cfb1e193f3a0c5dd1a6dca7804847f317e3ae4&w=826",
    },
    {
      img: "https://img.freepik.com/free-vector/certificate-template-with-elegant-elements_23-2148568461.jpg?t=st=1726815200~exp=1726818800~hmac=6fc5d66300495b62e14284ad81cfb1e193f3a0c5dd1a6dca7804847f317e3ae4&w=826",
    },
  ],
  description:
    "Welcome to Vinayaka multi-speciality hospital , where exceptional healthcare meets compassionate service. Our hospital center is not just a place for treatment; it's a sanctuary for healing and well-being. We pride ourselves on offering comprehensive medical services that cater to the unique needs of each patient. Our dedicated team of healthcare professionals is committed to providing personalized care in a comforting and supportive environment",
};

export const departMentHeading = {
  hading: "Department of medical health care",
  titel: "Choose your Service",
  description:
    "Our department is dedicated to providing comprehensive and high-quality medical services tailored to meet the diverse needs of our patients. We pride ourselves on combining cutting-edge technology with compassionate care to ensure that you receive the best possible treatment. Whether youneed routine check-ups, specialized care, or advanced diagnostic services, our experienced team is here to support you every step of the way. ",
};
